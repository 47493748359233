import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link } from 'gatsby'
import {
  faChevronLeft,
} from '@fortawesome/pro-solid-svg-icons'


import Layout from '../../components/layout'
import './styles.scss'
import styles from './styles.module.scss'

export default ({ pageContext: { doc } }) => {
  return (
    <Layout title="Analisis">
      <div className="Analysis search flex flex-col items-center content-center justify-center p-12">
        <h1 className="text-4xl mb-8">Estudios</h1>
      </div>

      <section className={`${styles.container} p-16`}>
        <div className="flex items-center border-b-2 border-gray-400 pb-5 mb-5">
          <div className="mr-3">
            <Link to={`/estudios`}><FontAwesomeIcon icon={faChevronLeft} /></Link>
          </div>
          <div className="text-2xl">
            <div>{doc.label}</div>
          </div>
        </div>

        <div class="shadow-2xl p-12">
          <div className="text-1xl border-b-2 border-gray-400 pb-5 mb-5">Preparación</div>
          <p className="text-gray-500 mb-10 whitespace-pre-line">{doc.description}</p>
          <div className="text-1xl border-b-2 border-gray-400 pb-5 my-5">Valores</div>
          <ul className="list-disc list-inside">
            {doc.items.map(item => <li key={item} className="text-gray-600">{item}</li>)}
          </ul>
        </div>
      </section>

    </Layout>
  )
}
